import logo from './logo.svg';
import './App.css';
import funds from './data/funds.json';
import fundSummaryMock from './data/fund_summary.json';
import categorySummaryMock from './data/category_summary.json';
import Chart from "react-apexcharts";
import { ReactSearchAutocomplete } from 'react-search-autocomplete'
import moment from 'moment/moment';
import { useState, useEffect } from 'react';

function App() {
  const [fundDtl, setFundDtl] = useState();

  const items = [];
  const fundMap = {};

  funds.forEach(function (oneFund, index) {
    let item = {
      id: oneFund.fund_id,
      name: oneFund.fund_name
    }
    items.push(item);
    fundMap[oneFund.fund_id] = oneFund;
  });


  const fuseOptions = {
    distance: 1000,
    threshold: 0.3,
    ignoreLocation: true
  }

  const handleOnSearch = (string, results) => {
  }

  const handleOnHover = (result) => {
  }

  const handleOnSelect = (item) => {
    if(item && item.id && fundMap[item.id]) {
		let fundDtlCache = fundMap[item.id];
		let requestBody = {fund_id: fundDtlCache.fund_id.toString(), fund_scheme: fundDtlCache.fund_scheme + "-" + fundDtlCache.fund_scheme_category};
		// POST request using fetch inside useEffect React hook
		const requestOptions = {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			body: JSON.stringify(requestBody)
		};
		fetch('https://h53emeq255.execute-api.ap-south-1.amazonaws.com/prod/fund', requestOptions)
			.then(response => response.json())
			.then(data => {
				let fund = {
					fund : fundMap[fundDtlCache.fund_id],
					fundSummary: data.fund_summary,
					categorySummary : data.category_summary   
				};
				processFundDtl(fund);
				setFundDtl(fund);
			});		
    }
  }

  const processFundDtl = (fund) =>{
	for (let categorySummary in fund.categorySummary) {
		if(categorySummary != "fund_scheme") {
			fund.categorySummary[categorySummary]=JSON.parse(fund.categorySummary[categorySummary])
		}
	}
	console.log(fund);
  }	  

  const handleOnFocus = () => {
  }

  const formatResult = (item) => {
    return (
      <>
        <span style={{ display: 'block', textAlign: 'left' }}>{item.name}</span>
      </>
    )
  }

  return (
    <div className="App">

      <header>
        <div className="text-success bg-dark">
          <div className="row align-items-center">
            <div className="col col-lg-1 ps-1 align-center">
            </div>
            <div className="col col-lg-1  align-center">
              <img src="/mfr-icon.png" className="rounded align-center" />
            </div>
            <div className="col col-lg-5 fs-2 text-white fs-1 fw-bolder">
              Mutual Funds Returns
              <p className="fs-6 fw-lighter fst-italic"><small><small>An AI generated non-sponsored tool to analyze performance of the funds based on its past performance</small></small></p>
            </div>
            <div className="col col-lg-4 fs-2 text-white fs-1 fw-bolder">
              <ReactSearchAutocomplete
                items={items}
                onSearch={handleOnSearch}
                onSelect={handleOnSelect}
                onFocus={handleOnFocus}
                fuseOptions={fuseOptions}
                autoFocus
                formatResult={formatResult}
              />
            </div>

          </div>
        </div>
      </header>

      
      { fundDtl &&
        <div className="container py-2 px-2 ">
          {fundNameSection(fundDtl.fund, fundDtl.fundSummary)}
          {fundShortTermReturns(fundDtl.fundSummary)}
          {fundLongTermReturns(fundDtl.fundSummary, fundDtl.categorySummary)}
          {prepareChartData(fundDtl.fundSummary)}
      </div>
    }

    </div>
  );
}


function fullDateFormat(value) {
  if (value) {
    return moment(value).format('LL');
  }
  return '';
}

function fundNameSection(fundDtl, fundSummary) {
  return (

    <div className="container pb-3 border-bottom border-primary">
      <div className="row">
        <div className="col h3">
          <span>{fundDtl.fund_name}</span>
        </div>
        <div className="col  fs-6 fw-lighter align-self-center fst-italic">
          <span>NAV & Returns updated as on {fullDateFormat(fundSummary.last_nav_date)}</span>
        </div>
      </div>
      <div className="row justify-content-start fw-lighter">
        <div className="col col-lg-2">
          <span>NAV (1 day change)</span>
        </div>
        <div className="col col-lg-2">
          <span>Fund House</span>
        </div>
        <div className="col col-lg-3">
          <span>Category</span>
        </div>
        <div className="col col-lg-2">
          <span>Fund Type</span>
        </div>
        <div className="col col-lg-2">
          <span>Scheme Details</span>
        </div>
      </div>
      <div className="row justify-content-start fw-bold ">
        <div className="col col-lg-2 ">
          {fundSummary.last_nav} <span className={getNumericClass(fundSummary.last_update)}> ({fundSummary.last_update}%)</span>
        </div>
        <div className="col col-lg-2">
          <span>{fundDtl.fund_house}</span>
        </div>
        <div className="col col-lg-3">
          <span>{fundDtl.fund_scheme} - {fundDtl.fund_scheme_category}</span>
        </div>

        <div className="col col-lg-2">
          <span>{fundDtl.fund_purchase_type} - {fundDtl.fund_return_type}</span>
        </div>
        <div className="col col-lg-2">
          <span>{fundDtl.fund_scheme_type}</span>
        </div>
      </div>
    </div>
  );
}

function fundShortTermReturns(fundSummary) {
  return (
    <div className="container pt-3 pb-3">
      <div className="row h5 justify-content-md-left ">
        <span>Short Term Returns</span>
      </div>
      <div className="row justify-content-start fw-lighter border-bottom">
        <div className="col">
          <span>1 Week</span>
        </div>
        <div className="col">
          <span>2 Weeks</span>
        </div>
        <div className="col">
          <span>1 Month</span>
        </div>
        <div className="col">
          <span>3 Months</span>
        </div>
        <div className="col">
          <span>6 Months</span>
        </div>
        <div className="col">
          <span>1 year</span>
        </div>
      </div>

      <div className="row justify-content-start fw-bold">
        <div className="col">
          <span className={"fw-bold " + getNumericClass(fundSummary.last_week)}>{absVal(fundSummary.last_week)} </span>
        </div>
        <div className="col">
          <span className={"fw-bold " + getNumericClass(fundSummary.last_fortnight)}>{absVal(fundSummary.last_fortnight)} </span>
        </div>
        <div className="col">
          <span className={"fw-bold " + getNumericClass(fundSummary.last_month)}>{absVal(fundSummary.last_month)} </span>
        </div>
        <div className="col">
          <span className={"fw-bold " + getNumericClass(fundSummary.last_quarter)}>{absVal(fundSummary.last_quarter)} </span>
        </div>
        <div className="col">
          <span className={"fw-bold " + getNumericClass(fundSummary.last_half_year)}>{absVal(fundSummary.last_half_year)} </span>
        </div>
        <div className="col">
          <span className={"fw-bold " + getNumericClass(fundSummary.last_1year)}>{absVal(fundSummary.last_1year)}% </span>
        </div>
      </div>


    </div>
  );
}

function fundLongTermReturnsRow(fundSummary, title, year) {
  return (
    <div className="row justify-content-start  border-bottom">
      <div className="col">
        <span className=" ">{title}</span>
      </div>
      <div className="col">
        <span className={getNumericClass(fundSummary.last_1year)}>{absVal(fundSummary.last_1year, year)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(fundSummary.last_2year)}>{absVal(fundSummary.last_2year, year * 2)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(fundSummary.last_3year)}>{absVal(fundSummary.last_3year, year * 3)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(fundSummary.last_4year)}>{absVal(fundSummary.last_4year, year * 4)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(fundSummary.last_5year)}>{absVal(fundSummary.last_5year, year * 5)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(fundSummary.last_5year)}>{absVal(fundSummary.last_10year, year * 10)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(fundSummary.inception)}>{absVal(year ? null : fundSummary.inception, 0)} </span>
      </div>
    </div>
  )
}

function fundLongTermCategoryReturnsRow(categorySummary, title, category) {
  return (
    <div className="row justify-content-start border-bottom">
      <div className="col">
        <span>{title}</span>
      </div>
      <div className="col">
        <span className={getNumericClass(categorySummary?.last_1year?.[category]?.avg)}>{absVal(categorySummary?.last_1year?.[category]?.avg, 1)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(categorySummary?.last_2year?.[category]?.avg)}>{absVal(categorySummary?.last_2year?.[category]?.avg, 2)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(categorySummary?.last_3year?.[category]?.avg)}>{absVal(categorySummary?.last_3year?.[category]?.avg, 3)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(categorySummary?.last_4year?.[category]?.avg)}>{absVal(categorySummary?.last_4year?.[category]?.avg, 4)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(categorySummary?.last_5year?.[category]?.avg)}>{absVal(categorySummary?.last_5year?.[category]?.avg, 5)} </span>
      </div>
      <div className="col">
        <span className={getNumericClass(categorySummary?.last_10year?.[category]?.avg)}>{absVal(categorySummary?.last_10year?.[category]?.avg, 10)} </span>
      </div>
      <div className="col">
        -
      </div>
    </div>
  );
}


function fundLongTermReturns(fundSummary, categorySummary) {
  return (
    <div className="container pb-3 border-bottom border-primary">
      <div className="row h5 justify-content-md-left ">
        <span>Long Term Returns</span>
      </div>
      <div className="row justify-content-start fw-lighter border-bottom">
        <div className="col">
          <span></span>
        </div>
        <div className="col">
          <span>1 Year</span>
        </div>
        <div className="col">
          <span>2 Year</span>
        </div>
        <div className="col">
          <span>3 Year</span>
        </div>
        <div className="col">
          <span>4 Year</span>
        </div>
        <div className="col">
          <span>5 Year</span>
        </div>
        <div className="col">
          <span>10 Year</span>
        </div>
        <div className="col">
          <span>Inception</span>
        </div>
      </div>
      {fundLongTermReturnsRow(fundSummary, 'Absolute', 0)}
      {fundLongTermReturnsRow(fundSummary, 'Annualized', 1)}
      {fundLongTermCategoryReturnsRow(categorySummary, 'Category Best', 'best')}
      {fundLongTermCategoryReturnsRow(categorySummary, 'Top 10 Average', 'top10')}
      {fundLongTermCategoryReturnsRow(categorySummary, 'Category Avgerage', 'cat')}
      {fundLongTermCategoryReturnsRow(categorySummary, 'Category Worst', 'worst')}
    </div>
  );

}

function prepareChartData(fundSummary) {
  let json = JSON.parse(fundSummary.quarterly_json);

  let cumulativeData = [];
  let yearlyData = [];
  let labels = [];

  let yearlyCumulativeReturns = 0, yearOnYearReturns = 0;
  for (let year in json) {
    yearOnYearReturns = 0;
    for (let quarter in json[year]) {
      yearlyCumulativeReturns += Number(Number(json[year][quarter]).toFixed(0));
      yearOnYearReturns += Number(Number(json[year][quarter]).toFixed(0));
    }
    cumulativeData.push(yearlyCumulativeReturns);
    yearlyData.push(yearOnYearReturns);
    labels.push(year);
  }

  let options = {
    chart: {
      id: 'ID'
    },
    xaxis: {
      categories: labels
    },
    toolbar: {
      show: false
    },
    zoom: {
      enabled: false
    },
    fill: {
      opacity: 0.75
    },
    legend: {
      position: 'right',
      offsetY: 40
    },
    colors: [function ({ value, seriesIndex, w }) {
      if (value < 0) {
        return '#dc3545'
      } else {
        return '#198754'
      }
    }, function ({ value, seriesIndex, w }) {
      if (value < 0) {
        return '#dc3545'
      } else {
        return '#198754'
      }
    }]
  };


  let cumulativeSeriesObj = [];
  let cumulativeSeries = {
    name: "Yearly Cumulative Retuns",
    data: cumulativeData
  };
  cumulativeSeriesObj.push(cumulativeSeries);

  let yearlySeriesObj = [];
  let yearlySeries = {
    name: "Yearly on Year Retuns",
    data: yearlyData
  };
  yearlySeriesObj.push(yearlySeries);


  return (
    <div className="container pb-3 border-bottom border-primary">
      <div className="row h3 justify-content-start text-center">
        <div className="col">
          Cumulative Returns %
        </div>
        <div className="col">
          Year on Year Returns %
        </div>
      </div>
      <div className="row justify-content-start ">
        <div className="col">
          <Chart
            options={options}
            series={cumulativeSeriesObj}
            type="line"
            height="300px"
            width="535px"
          />
        </div>
        <div className="col">
          <Chart
            options={options}
            series={yearlySeriesObj}
            type="bar"
            height="300px"
            width="535px"
          />
        </div>
      </div>
    </div>
  );
}

function absVal(value, year) {
  if (value || value == 0) {
    let pa = '';
    if (year) {
      let pa = Number(value) / year;
      return pa.toFixed(2) + "%";
    }
    return value + "%";
  }
  return "-";
}

function getNumericClass(value) {
  if (value || value == 0) {
    let valueInt = Number(value);
    return (valueInt < 0) ? "text-danger" : "text-success";
  }
  return "";
}

export default App;
